/*TYPOGRAPHY*/
body {
  color: $color-text;
  font-family: $font-text;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading,
.display-1,
.display-2,
.heading-sm-1
{
  font-family: $font-heading;
  color: $color-heading;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}
.display-1 {
  font-size: 96px;
  line-height: 1;
}
.display-2 {
  font-size: 72px;
  line-height: 1;
}
.heading-sm-1 {
  font-size: 14px;
}
p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  color: $color-text;
}
.text-heading {
  color: $color-heading;
}
p:last-child {
  margin-bottom: 0;
}
.font-weight-bold {
  font-weight: 700;
}
a,
button {
  text-decoration: none;
  cursor: pointer;
}
b {font-weight: 500;}
strong,
.fw-600 {
  font-weight: 600;
}
.fw-900 {
  font-weight: 900;
}
.fw-300 {
  font-weight: 300;
}
.section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: end;
  margin-bottom: 44px;
  position: relative;
  justify-content: space-between;
  .title {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
  }
  
  h3 {
    font-weight: 700;
    font-size: 32px;
    margin-right: 30px;
  }
  a.show-all {
    font-size: $font-md;
    color: $color-text;
    &:hover {
      color: $color-brand;
    }    
    i {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  &.style-1 {
    position: relative;
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
    font-size: 24px;
    &::after {
      content: "";
      width: 80px;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $border-color-2;
    }
  }
  span {
    color: $color-brand;
  }
}

h5.widget-title {
  font-size: 18px;
  font-weight: 600;
}
.title.style-3 {
  background-image: url('../../../../../public/images/theme/wave.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}
.text-body {color: $color-text !important;}
.font-xxs{font-size:$font-xxs;}
.font-xs{font-size:$font-xs;}
.font-sm{font-size:$font-sm;}
.font-md{font-size:$font-md;}
.font-lg{font-size:$font-lg;}
.font-xl{font-size:$font-xl;}
.font-xxl{font-size:$font-xxl;}

.text-hot {color: $color-hot}
.text-new {color: $color-new}
.text-sale {color: $color-sale}
.text-best {color: $color-best}

.text-style-1 {
  position: relative;
  &::after {    
    content: "";
    background-color: #ffdabf;
    height: 20%;
    width: 110%;
    display: block;
    position: absolute;
    bottom: 20%;
    left: -5%;
    z-index: -1;
    opacity: 0.8;
    @include transform-duration-5s;
  }
  &:hover::after {
    height: 30%;
    @include transform-duration-5s;
  }
}
.fw-700 {font-weight: 700;}