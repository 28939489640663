/*Page > About*/
.hero-2 {
	padding: 160px 0 100px;
	min-height: 640px;
}
.hero-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: content;
    flex-direction: column;
    background:url('../../../../../public/images/page/home-6-bg.jpg') no-repeat center center;
    background-size: cover;
    height: 330px;
     form {
        background-color: #fff;
        max-width: 520px;
        border-radius: 50px;
        box-shadow: $box-shadown-1;
        input {
            border: 0;
            border-radius: 50px 0 0 50px;
            padding-left: 58px;
            background: url('../../../../../public/images/theme/icons/icon-search.png') no-repeat 25px center;
        }
        button {
            border: 0;
            border-radius: 50px;
        }
    }
    .nav-link {
        font-size: 14px !important;
    }
}

.parallax-wrapper {
	position: absolute;
	z-index: 3;
	width: 100%;

    .parallax-img-area {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        z-index: 2;
        margin: 0 auto;
        .parallax-img {
            position: absolute;
            z-index: 2;
            text-align: right;
            &.img-1 {
                right: 0;
                width: 52%;
                z-index: 2;
                top: 100px;
            }
            &.img-2 {
                right: 220px;
                width: 52%;
                top: 40px;
                z-index: 3;
            }
            &.img-3 {
                opacity: 0.1!important;
                left: -210px;
                width: 320px;
                top: 193px;
            }
            &.img-4 {
                opacity: 0.08!important;
                width: 180px;
                left: 50%;
            }
            &.img-5 {
                right: 0;
                width: 12%;
                z-index: 2;
                opacity: 0.3;
                bottom: 20%;
            }
            &.img-6 {
                width: 25%;
                z-index: 3;
                opacity: 0.2;
                bottom: 0;
                left: -150px;
            }
            &.img-7 {
                opacity: 0.2!important;
                width: 16%;
                top: 10%;
                left: 10%;
            }
            &.img-8 {
                opacity: 0.2!important;
                width: 10%;
                bottom: 40%;
                left: 50%;
            }
        }
    }
}

.hero-content {
	position: absolute;
	z-index: 4;
	width: 100%;
	h1 {
		line-height: 1.1;
	}
}


.about-count {
	z-index: 100;
	position: relative;
    color: #fff;
    background:url('../../../../../public/images/page/about-9.png') no-repeat center center;
    border-radius: 15px;
    padding: 100px 0;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #4B675A;
        z-index: 2;
        opacity: 0.8;
    }
    h1 {
        font-size: 72px; color: #fff;
    }
    h4 {
        color: #fff;
    }
    .text-center {
        z-index: 3;
        position: relative;
    }
}

.hero-card-icon {
	width: 65px;
	height: 65px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	&.icon-left {
		width: 165px;
		-webkit-box-align: start;
		-webkit-align-items: start;
		-ms-flex-align: start;
		align-items: start;
	}
}

.single-content {
	& > ol {
		list-style-type: decimal;
		margin-bottom: 30px;
		padding-left: 1em;
		li {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
        ol {
            list-style-type: lower-alpha;
            margin: 20px 0 30px;
            padding-left: 25px;
            ol {
                list-style-type: lower-roman;
            }
        }
	}
}
/*page 404*/
.page-404 {
    background-color: #fff;
    img {
        max-width: 300px;
        &.logo {
            max-width: 150px;
        }
    }
    .search-form {
        max-width: 400px;
        margin: 0 auto;
    }
}