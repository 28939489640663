.invoice-content {
    .invoice-header-1 {
        text-transform: uppercase;
        font-weight: 700;
    }
    .invoice-header-2 {
        text-transform: uppercase;
        font-weight: 600;
    }
    .invoice-title-1 {
        font-size: 18px;
    }
    .invoice-addr-1 {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .item-desc-1 {
        text-align: left;
        span {
            display: block;
        }
        small {
            display: block;
        }
    }
    .important-notes-list-1 {
        font-size: 13px !important;
        padding-left: 15px;
        margin-bottom: 15px;
        list-style: disc;
        li {
            margin-bottom: 5px;
        }
    }
    .bank-transfer-list-1 {
        font-size: 13px !important;
        padding-left: 0px;
    }
    .important-notes {
        font-size: 12px !important;
    }
    .invoice-btn-section {
        text-align: center;
        margin-top: 30px;
    }

    .btn-lg {
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding: 0 35px;
        line-height: 50px;
        border-radius: 3px;
        color: #ffffff;
        border: none;
        margin: 3px;
        display: inline-block;
        vertical-align: middle;
        -webkit-appearance: none;
        text-transform: capitalize;
        transition: all 0.3s linear;
        z-index: 1;
        position: relative;
        overflow: hidden;
        text-align: center;
        font-family: $font-heading;
    }
    .btn-check:focus + .btn,
    .btn:focus {
        outline: 0;
        box-shadow: none;
    }
    .btn-download {
        background: $color-brand;
        &:after {
            background: $color-brand-dark;
        }
    }
    .btn-print {
        background: $color-heading;
        &:after {
            background: $color-grey-2;
        }
    }
    .invoice-content .invoice-table {
        th:first-child,
        td:first-child {
            text-align: left;
        }
    }
    .btn-custom {
        border: none;
        z-index: 1;
        img {
            max-height: 14px;
            margin-right: 5px;
        }
        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
            box-shadow: inset 0px 0px 0px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
        }
        &:hover {
            color: #fff;
            &:after {
                left: 0;
                width: 100%;
            }
        }
        &:active {
            top: 2px;
        }
    }
    table {
        thead {
            font-family: $font-heading;
            color: $color-heading;
            font-weight: 700;
        }
    }
    .f-w-600 {
        font-weight: 600;
    }
    .mobile-social-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: center;
        h6 {
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 0;
        }
        a {
            text-align: center;
            font-size: 14px;
            margin-right: 5px;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            height: 30px;
            width: 30px;
            display: inline-flex;
            background: $color-brand;
            border-radius: 30px;
            line-height: 1;
            align-content: center;
            justify-content: center;
            img {
                max-width: 16px;
                &:hover {
                    opacity: 0.8;
                }
            }
            &:hover {
                transform: translateY(-2px);
                transition-duration: 0.5s;
                margin-top: -2px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/** Invoice 1 **/
.invoice {
    padding: 50px 0;
    background: $background-1;
    .invoice-info {
        background: #fff;
        margin-bottom: 30px;
        border-radius: 20px;
        box-shadow: $box-shadown-3;
    }
    .invoice-inner {
        max-width: 1296px;
        margin: 0 auto;
    }
    .item-desc-1 {
        small {
            font-size: 14px;
        }
        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
    .invoice-header {
        padding: 80px 150px;
        border-radius: 20px 20px 0 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
    }
    .table-striped {
        & > tbody {
            & > tr {
                &:nth-of-type(odd) {
                    --bs-table-accent-bg: rgb(255 255 255 / 5%);
                    color: var(--bs-table-striped-color);
                }
            }
        }
    }
    .invoice-top {
        padding: 40px 150px 25px 150px;
        font-size: 15px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 50px;
    }
    .invoice-center {
        padding: 0 150px 40px;
        .table {
            margin-bottom: 0;
        }
    }
    .table-section {
        text-align: center;
    }
    .table {
        & > :not(caption) {
            & > * {
                & > * {
                    padding: 15px 20px;
                }
            }
        }
        td {
            font-size: 15px;
            font-weight: 400;
        }
    }
    table {
        th {
            font-size: 15px;
        }
    }
    .caption-top {
        caption-side: top;
        text-align: right;
        margin-bottom: 0;
    }
    .invoice-bottom {
        padding: 0 150px 25px;
        .amount {
            text-align: right;
        }
        h3 {
            margin-bottom: 15px;
        }
    }
    .bg-active {
        background: #f3f3f3;
        color: #535353 !important;
    }
    .invoice-contact {
        padding: 70px 150px 40px;
    }
    .social-list {
        float: left;
        span {
            margin-right: 5px;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
        }
        a {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            display: inline-block;
            font-size: 17px;
            background: #fff;
            margin: 0 2px 2px 0;
            color: #25cc7e;
            border-radius: 50%;
            &:hover {
                background: #fff6f6;
            }
        }
    }
}
.invoice-top {
    .float-end {
        p {
            margin-bottom: 0;
        }
    }
}
.invoice-1 .invoice-info-buttom .table .invoice-1 .invoice-info-buttom .table tr,
.table tr {
    border: 1px solid #e9ecef;
}
.table {
    & > :not(caption) {
        & > * {
            & > * {
                background-color: var(--bs-table-bg);
                border-bottom-width: 0;
            }
        }
    }
}
.invoice-1 .table td,
.invoice-1 .table th {
    vertical-align: middle;
    border: none !important;
}

.invoice-1 {
    .invoice-header {
        background: rgba(0, 0, 0, 0.04) url('../../../../../public/images/invoice/header-bg-1.png') top left repeat;
    }
}

.invoice-2 {
    .invoice-info {
        border-radius: 0;
    }
    .invoice-header {
        border-bottom: 1px solid $border-color;
    }
}
.invoice-3 {
    .invoice-header {        
        background: url('../../../../../public/images/invoice/header-bg-2.jpg') top left no-repeat;
        background-size: cover;
        padding: 50px 150px;
        color: #fff;
    }
    .invoice-table {
        thead {
            color: #fff !important;
            background-color: $color-brand;
        }
    }
}
.invoice-4 {
    .invoice-info {
        border-radius: 5px;
    }
    .invoice-header {
        border-bottom: 1px solid $border-color;
        padding: 50px 150px 30px 150px;
        margin-bottom: 50px;
    }
    .invoice-bottom {
        .hr {
            width: 100%;
            height: 1px;
            background-color: $border-color;
        }
    }
}
.invoice-5 {
    .invoice-info {
        border-radius: 0;
    }
    .invoice-header {
        padding: 50px 150px;
    }
    .invoice-banner {
        padding: 0 150px 50px 150px;
    }
    .invoice-bottom {
        .hr {
            width: 100%;
            height: 1px;
            background-color: $border-color;
        }
    }
}

.invoice-6 {
    &.invoice {
        background-color: #fff;
    }

    .invoice-info {
        border-radius: 10px;
        border: 1px solid $border-color-2;
        box-shadow: $box-shadown-2;
    }
    .hr {
        width: 100%;
        height: 1px;
        background-color: $color-brand;
    }
    .invoice-header {
        background-color: #f7f8f9;
        padding: 80px 150px 50px 150px;
        margin-bottom: 50px;
        border-radius: 10px 10px 0 0;
    }
    .invoice-icon {
        border-radius: 0 10px 0 0;
    }
}
.invoice-icon {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: $color-brand;
    img {
        width: 30px;
    }
}
/*Responsive*/
@media only screen and (max-width: 992px) {
    .invoice {
        .invoice-header {
            padding: 80px 50px;
        }
        .invoice-top {
            padding: 40px 50px 25px 50px;
        }
        .invoice-center {
            padding: 0 50px 40px;
        }
        .invoice-bottom {
            padding: 0 50px 25px;
        }
        .back-top-home {
            margin-bottom: 30px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 480px) {
}
