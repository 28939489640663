#carausel-8-columns > .card-1 {
  display: inline-block;
}

#carausel-4-columns > .slick-list > .product-cart-wrap {
  width: 278px;
  display: inline-block;
  /* card-1 slick-slide slick-cloned slick-active; */
}

.more_categories:hover {
  cursor: pointer;
}

.more-cat-box {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

#navbar-bottom-categories {
  width: 75%;
}

.link-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.see-all-brands {
  max-height: 297px;
  overflow: auto;
}

.form-control-xs {
  height: 50px;
}

.wisilist-fill {
  font-size: 20px !important;
}

.categories-button-active {
  width: max-content;
}

.main-menu.main-menu-padding-1 > nav {
  width: max-content;
}

.sub-menu {
  position: absolute;
  width: fit-content;
  left: 101%;
  padding-left: 15px !important;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 0;
  padding-top: 1.25rem;
  list-style: none;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-top: 0;
  border-bottom-right-radius: var(--size-border-radius);
  white-space: nowrap;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.25s cubic-bezier(0.28, 0.12, 0.22, 1);
}

.parent-cat {
  justify-content: space-between;
}

.parent-cat .subcat-icon {
  font-size: 10px;
}

.parent-cat:hover + .sub-menu {
  opacity: 1;
  visibility: visible;
  height: fit-content;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.sub-menu:hover {
  opacity: 1;
  visibility: visible;
  height: fit-content;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.sub-menu + .sub-cat {
  width: max-content;
}

.show_cat {
  opacity: 1;
  visibility: visible;
  height: fit-content;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.sub-menu li {
  /* display: flex; */
  /* min-width: max-content;
    max-width: max-content;
    width: max-content; */
  align-items: center;
  line-height: 48px;
  border-radius: 5px;
  border: 1px solid #f2f3f4;
  padding: 9px 18px;
  margin: 0 15px 15px 0;
  height: 50px;
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

.sub-cat:hover {
  border: 2px solid var(--border-color) !important;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%) !important;
  transition: 0.3s;
  cursor: pointer;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
}

.sub-menu.show_cat {
  pointer-events: auto !important;
}

.subcat-icon-hover {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main-cat-checkbox {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0 0 15px 0;
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

.main-cat-checkbox span {
  margin-left: 10px;
  font-weight: bold;
}
.slick-cloned img:hover {
  border: 2px solid var(--primary-color);
  opacity: 0.8;
}
.product-cart-wrap .product-img-action-wrap {
  padding: 0px !important;
}
.product-cart-wrap .product-img-action-wrap .product-img {
  border-radius: 0px !important;
}

.contact-email:hover {
  color: white !important;
}

.contact-number {
  font-weight: bolder;
}

.contact-web {
  color: #e57d26 !important;
}
.show-password {
  cursor: pointer;
  float: right;
  position: relative;
  margin-top: -45px;
  margin-right: 15px;
  font-size: 20px;
  width: 30px;
  background: var(--primary-color);
  border-radius: 50%;
}
.show-certificate {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  width: 30px;
  background: var(--primary-color);
  border-radius: 50%;
}
.show-delete {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  width: 30px;
  background: var(--primary-color);
  border-radius: 50%;
}

.notification-modal .notification-ui_dd-content {
  max-height: 530px;
  overflow-y: auto;
}

.notification-list--unread {
  border-left: 2px solid #29b6f6;
}
.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 7px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
}
.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list_detail > p:first-child {
  color: black;
}

.notify-action {
  margin-top: 20px;
  justify-content: space-evenly;
  display: flex;
}

.modal-title-notify {
  color: black;
}

.see-all-label > span {
  color: black !important;
}

.no-notification {
  color: black;
  text-align: center;
}
.group_item .old-price {
  text-decoration: line-through;
  color: #b6b6b6;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 700;
}
.cart_input {
  width: 35%;
  height: 100% !important;
  border: 1px solid var(--primary-color) !important;
  color: #fff;
  background-color: var(--dark-background);
  margin-right: 5px;
}
.cart-product-name-wc,
.whishlist-product-name-wc {
  width: 30% !important;
  margin-left: 5px !important;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;

  /* display: block;
  overflow: hidden;
  height: 60px;
  white-space: nowrap;
  text-overflow: ellipsis; */

  /* width: 230px; */
}

.detail-qty > a:hover {
  color: var(--border-color);
}
