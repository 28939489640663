#carausel-8-columns > .card-1 {
  width: 183px;
}

.sort-price-dropdown {
  width: max-content;
}

.hotline p {
  font-size: 22px !important;
}

@media only screen and (max-width: 414px) {
  .register-page,
  .login-page {
    padding: 40px 0 !important;
  }

  .newsletter .newsletter-inner {
    overflow: auto !important;
    margin-bottom: 100px !important;
  }

  .newsletter-content .form-subcriber {
    position: absolute !important;
    margin-top: 100px !important;
  }

  .hero-slider-1 .single-hero-slider {
    border-radius: 10px !important;
  }

  .single-hero-slider.single-animation-wrap {
    height: 65% !important;
  }

  .hero-slider-1 .slider-content {
    top: 25% !important;
  }

  .home-slide-cover {
    margin-bottom: -50% !important;
  }

  #carausel-8-columns > .card-1 {
    margin: 10px 5px !important;
    width: 177px !important;
  }

  .carausel-8-columns {
    justify-content: space-evenly;
  }

  .product-grid-4 {
    justify-content: space-evenly;
  }

  .inner-product-grid {
    width: 187px !important;
    margin: 0 0px !important;
  }

  .product-img-action-wrap {
    padding: 0px !important;
  }

  .product-content-wrap {
    padding: 8px !important;
  }

  .product-cart-wrap .product-card-bottom {
    display: block;
    margin: 0px;
  }

  .product-cart-wrap .product-card-bottom div a {
    text-align: right;
    width: 90% !important;
  }

  .product-card-bottom .add-cart {
    margin: 5px 0 !important;
    display: flex;
    justify-content: center;
  }

  .contact-page {
    text-align: center;
  }

  .dashboard-content {
    padding-left: 0px !important;
    margin-top: 10px !important;
  }

  .dashboard-order {
    display: block !important;
  }

  .dashboard-order-list {
    display: flex !important;
  }

  .cart-totals {
    margin-top: 20px !important;
  }

  .delete-cart {
    padding: 10px 0px !important;
  }

  .detail-info,
  .whishlist-padding {
    padding: 10px 0px !important;
  }

  .mobile-whishlist,
  .mobile-cart {
    display: none !important;
  }

  .product-category-name {
    width: auto !important;
  }

  .detail-qty {
    padding: 12px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .product-cart-wrap .product-img-action-wrap .product-action-1 {
    min-width: 35px !important;
  }
}

@media only screen and (max-width: 480px) {
  .group-item {
    display: flex !important;
  }

  .group-item-qty {
    width: 140% !important;
  }
}

@media only screen and (max-width: 360px) {
  .inner-product-grid {
    width: 100% !important;
  }

  #carausel-8-columns > .card-1 {
    width: 80% !important;
  }
}

@media only screen and (width: 540px) {
  .inner-product-grid {
    width: 270px !important;
  }

  #carausel-8-columns > .card-1 {
    width: 240px !important;
  }

  .mobile-whishlist,
  .mobile-cart {
    display: block !important;
  }

  .mobile-w-c {
    display: none !important;
  }

  .table-wishlist thead {
    display: none !important;
  }

  .mobile-single-product {
    margin-top: 15px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .logo.logo-width-1 a img,
  .mobile-header-logo {
    width: 50px !important;
    min-width: 50px !important;
  }
}

@media only screen and (max-width: 580px) {
  .group_item_table {
    overflow-x: inherit !important;
  }
}

@media only screen and (min-width: 1300px) {
  #hover-area:hover #hidden {
    height: 140px;
    visibility: visible;
    transition: height 0.5s, visibility 0s;
  }

  .inner-product-grid {
    position: relative;
  }

  #hover-area:hover {
    position: absolute;
    z-index: 1;
    min-width: 210px;
    max-width: 290px;
    width: 100%;
  }

  #hidden {
    width: 140px;
    visibility: hidden;
    height: 0;
    transition: height 0.5s, visibility 0s 0s;
  }

  /*.product-cart-wrap #hidden {
        width: 140px;
        visibility: hidden;
        height: 0;
        transition:
            height 0.5s,
            visibility 0s 0s;
    }

    .inner-product-grid #hover-area:hover #hidden {
        height: 140px;
        width: 100%;
    }*/
}

.header-style-1 .search-style-2 form input {
  width: 100% !important;
}

.banner-img.style-3 {
  height: 100% !important;
}
