/*COMPONENTS -> CARD*/
.card-1 {
    position: relative;
    background: $background-2;
    text-align: center;
    border: 1px solid $background-2;
    border-radius: 10px;
    padding: 40px 30px 28px 30px;
    margin-bottom: 20px;
    min-height: 215px;
    transition: 0.2s;
    &:hover {
        background: #fff;
        border: 1px solid $border-color-2;
        box-shadow: $box-shadown-2;
        transition: 0.2s; 
    }
    figure {
        text-align: center;
        margin-bottom: 20px;
        img {
            border-radius: 10px;
            display: inline-block;
            max-width: 80px;
        }
    }

    h6 {
        margin:0;
        a {
            color: $color-heading;            
        }
    }
    &:hover {
        a {color: $color-brand}
    }
}

.card-2 {
    position: relative;
    background: $background-2;
    text-align: center;
    border: 1px solid $background-2;
    border-radius: 10px;
    padding: 20px 0px 18px 0px;
    margin-bottom: 20px;
    min-height: 180px;
    transition: 0.2s;
    &:hover {
        background: #fff;
        border: 1px solid $border-color-2;
        box-shadow: $box-shadown-2;
        transition: 0.2s; 
    }
    figure {
        text-align: center;
        margin-bottom: 10px;
        img {
            border-radius: 10px;
            display: inline-block;
            max-width: 80px;
        }
    }

    h6 {
        margin:0;
        a {
            color: $color-heading;            
        }
    }
    &:hover {
        a {color: $color-brand}
    }
}

.hero-card {
	width: 100%;
	position: relative;
	background: #fff;
	padding: 30px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
    border: 1px solid #eee;
    &:hover {
        border: 1px solid $color-brand;
    }
    .hero-card-icon {
        width: 65px;
        height: 65px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &.icon-left {
            width: 135px;
            -webkit-box-align: start;
            -webkit-align-items: start;
            -ms-flex-align: start;
            align-items: start;
        }
        &.icon-left-2 {
            width: 265px;
            -webkit-box-align: start;
            -webkit-align-items: start;
            -ms-flex-align: start;
            align-items: start;
        }
        i {
            font-size: 25px;
            color: #6143f7;
        }
    }
}
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid $border-color;
	border-radius: .25rem;
    .card-header {
        padding: 1rem;
        margin-bottom: 0;
        background-color: #f7f8f9;
        border-bottom: 1px solid $border-color;
    }
}

.featured-card {
    padding: 50px 30px;
    border-radius: 15px;
    border: 1px solid $border-color;
    background: #fff;
    img {
        margin-bottom: 30px;
        width: 100px;
    }
    h4 {
        margin-bottom: 30px;
    }
    p {
        font-size: $font-lg;
        margin-bottom: 30px;
    }
    a {
        font-size: $font-md;
    }
    &:hover {
        box-shadow: $box-shadown-2;
    }
}
.team-card {
    position: relative;
    img {
        border-radius: 15px;
        z-index: 1;
    }
    &:hover {
        .content {
            transform: translateY(-95px);
            transition: 0.2s;
        }
    }
    .content {
        box-shadow: $box-shadown-2;
        border-radius: 15px;
        background: #fff;
        padding: 30px;
        max-width: 80%;
        position: relative;
        z-index: 2;
        transform: translateY(-90px);
        margin-left: auto;
        margin-right: auto;
        transition: 0.2s;
        span {
            font-size: $font-lg;
        }
        .social-network {
            a {
                display: inline-block;
                padding: 0 5px;
                img {
                    max-width: 30px;
                    min-width: 20px;
                }
            }
        }
    }
}
.account {
    .card {
        border: 0;
        .card-header {
            border: 0;
            background: none;
        }
        table td, table th {border: 0;}
        .table > thead {
            font-family: $font-heading;
            font-size: $font-lg;
        }
    }
}
.card-login {
    padding: 50px;
    border-radius: 15px;
    border: 1px solid $border-color;
    margin-left: 30px;
    .social-login {
        font-size: 20px;
        font-weight: 700;
        font-family: $font-heading;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px 25px;
        border-radius: 10px;
        margin-bottom: 20px;
        transition: 0.3s;
        img {
            min-width: 28px;
            max-width: 28px;
            margin-right: 15px;
        }
        &.facebook-login {
            background-color: #1877F2;
            color: #fff;
        }
        &.google-login {
            background-color: #fff;
            color: $color-text;
            border: 1px solid #F2F3F4;
        }
        &.apple-login {
            background-color: #000000;
            color: #fff;
            margin-bottom: 0;
        }
        &:hover {
            transform: translateY(-3px);
            transition: 0.3s;
            box-shadow: $box-shadown-2;
        }
    }
}