/*SHOP*/

.product-cart-wrap {
	position: relative;
	background-color: #fff;
	border: 1px solid $border-color;
	border-radius: 15px;	
	overflow: hidden;	
	@include transistion-duration-2s;

	&:hover {
		box-shadow: $box-shadown-2;
		border: 1px solid $border-color-2;
		@include transistion-duration-2s;
	}

	.product-img-action-wrap {
		position: relative;
		background-color: #fff;
		overflow: hidden;
		max-height: 320px;
		padding: 25px 25px 0 25px;
		.product-action-1 {
			background-color: #fff;
			border-radius: 5px;
			border: 1px solid $border-color-2;
		}
		.product-img {
			position: relative;
			overflow: hidden;
			border-radius: 15px;			
			a {
				overflow: hidden;
				display: flex;
				img {
					width: 100%;
					&.hover-img {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 2;
						opacity: 0;
						visibility: hidden;
						transition: 0.25s opacity, 0.25s visibility, transform 1.5s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 1.5s cubic-bezier(0, 0, 0.2, 1);
					}
				}
			}
			
		}
		.product-img-zoom {
			a {
				img {
					-webkit-transition: all 1.5s cubic-bezier(0, 0, 0.05, 1);
					-o-transition: all 1.5s cubic-bezier(0, 0, 0.05, 1);
					transition: all 1.5s cubic-bezier(0, 0, 0.05, 1);
				}
			}
		}
		
	}
	.product-action-1 {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all .3s ease 0s;
		-o-transition: all .3s ease 0s;
		transition: all .3s ease 0s;
		z-index: 9;
		box-shadow: $box-shadown-1;
		&.show {
			visibility: visible;
			opacity: 1;
			bottom: 26px;
			left: unset;
			right: 20px;
			top: unset;
			transform: none;
		}
		button, a.action-btn {
			width: 40px;
			height: 36px;
			line-height: 40px;
			text-align: center;
			position: relative;
			display: inline-block;
			border-right: 1px solid $border-color-2;
			&:last-child {
				border: none;
			}
			&.small {
				width: 32px;
				height: 32px;
				line-height: 32px;
				i {
					font-size: 12px;
				}
			}
			&:after {
				bottom: 100%;
				left: 50%;
				position: absolute;
				white-space: nowrap;
				border-radius: 5px;					
				font-size: 11px;
				padding: 7px 10px;
				color: #ffffff;
				background-color: $color-brand;
				content: attr(aria-label);
				line-height: 1.3;
				-webkit-transition-delay: .1s;
				-o-transition-delay: .1s;
				transition-delay: .1s;
				-webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
				box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
				transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
				opacity: 0;
				visibility: hidden;
				-webkit-transform: translateX(-50%) translateY(0px);
				-ms-transform: translateX(-50%) translateY(0px);
				transform: translateX(-50%) translateY(0px);
			}
			&:before {
				content: '';
				position: absolute;
				left: calc(50% - 7px);
				bottom: 100%;
				-webkit-transition-delay: .1s;
				-o-transition-delay: .1s;
				transition-delay: .1s;
				border: 7px solid transparent;
				border-top-color: $color-brand;
				z-index: 9;
				margin-bottom: -13px;
				transition-delay: .1s;
				transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
				opacity: 0;
				visibility: hidden;
			}
			&:last-child {
				margin-right: 0;
			}
			i {
				font-size: 15px;
				margin-left: 1px;
			}
			&:hover {				
				color: $color-brand-2;
				&:after {
					opacity: 1;
					visibility: visible;
					-webkit-transform: translateX(-50%) translateY(-8px);
					-ms-transform: translateX(-50%) translateY(-8px);
					transform: translateX(-50%) translateY(-8px);
				}
				&:before {
					opacity: 1;
					visibility: visible;
					-webkit-transform: translateY(-8px);
					-ms-transform: translateY(-8px);
					transform: translateY(-8px);
				}
				i {
					color: $color-brand-2;
				}
			}
		}
	}
	.product-badges {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		&.product-badges-mrg {
			margin: 0 0 10px;
		}
		&.product-badges-position {
			position: absolute;
			left: 0;
			top: 0px;
			z-index: 9;
		}
		span {
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			border-radius: 15px 0 20px 0;
			color: #fff;
			padding: 9px 20px 10px 20px;
			&:last-child {
				margin-right: 0px;
			}
			&.hot {
				background-color: $color-hot;
			}
			&.new {
				background-color: $color-brand;
			}
			&.sale {
				background-color: $color-sale;
			}
			&.best {
				background-color: $color-best;
			}
		}
	}
	.product-content-wrap {
		padding: 0 20px 20px 20px;
		.product-category {
			margin-bottom: 5px;
			a {
				color: $color-grey-4;
				font-size: 12px;
				&:hover {
					color: $color-brand;
				}
			}
		}
		h2 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 5px;
			a {
				color: $color-heading;
				&:hover {
					color: $color-brand;
				}
			}
		}
		.product-price {
			padding-top: 5px;
			span {
				font-size: 18px;
				font-weight: bold;
				color: $color-brand;
				&.new-price {
					color: $color-brand;
				}
				&.old-price {
					font-size: $font-sm;
					color: $color-grey-4;
					margin: 0 0 0 7px;
					text-decoration: line-through;
				}
			}
		}
		.rating-result {
			display: flex;
			> span {
				display: block;
				overflow: hidden;
				margin-left: 5px;
			}	
			&::before {
				font-family: "uicons-regular-straight"  !important;
				font-size: 11px;
				letter-spacing: 2px;
				content: '\f225' '\f225' '\f225' '\f225' '\f225';
				color: $color-warning;
			}		
		}
		.add-to-cart {
			width: 44px;
			height: 44px;
			line-height: 44px;
			border-radius: 5px;
			background-color: #f5f5f5;
			text-align: center;
			display: block;
			color: $color-grey-1;
			font-size: 16px;
			position: absolute;
			bottom: 25px;
			right: 20px;
			font-weight: 300;
			&:hover {
				background-color: $color-brand;
				color: #fff;
			}
			img {
				width: 20px;
				display: inline-block;
				margin-top: 11px;
			}
		}
		
	}
	.product-card-bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
		.add-cart {
			cursor: pointer;
			.add {
				position: relative;
				display: inline-block;
				padding: 6px 20px 6px 20px;
				border-radius: 4px;
				background-color: $background-3;
				font-size: 14px;
				font-weight: 700;
				&:hover {
					background-color: $color-brand;
					color: #fff;
					transform: translateY(-3px);
					box-shadow: $box-shadown-2;
				}
			}
		}
	}
	.product-stock {
		.status-bar {
			background-color: #ededed;
			margin: 0px 0 10px;
			border-radius: 5px;
			.sold-bar {
				background-image: -webkit-linear-gradient(215deg, $color-brand 0%, #77ccfd 100%);
				background-image: -o-linear-gradient(215deg, $color-brand 0%, #77ccfd 100%);
				background-image: linear-gradient(235deg, $color-brand 0%, #77ccfd 100%);
				border-radius: 4px;
				height: 8px;
				&.sold-bar-width-33 {
					width: 33.333333333333%;
				}
				&.sold-bar-width-10 {
					width: 10%;
				}
				&.sold-bar-width-40 {
					width: 40%;
				}
				&.sold-bar-width-6 {
					width: 6.6666666666667%;
				}
				&.sold-bar-width-42 {
					width: 42.857142857143%;
				}
			}
		}
		.product-stock-status {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			.sold {
				-webkit-box-flex: 1;
				-webkit-flex-grow: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				margin-right: 20px;
			}
			.available {
				-webkit-flex-shrink: 0;
				-ms-flex-negative: 0;
				flex-shrink: 0;
			}
			.stock-status-same-style {
				span {
					font-size: 15px;
					&.label {
						color: $color-heading;
					}
					&.value {
						font-weight: 700;
						color: #333;
					}
				}
			}
		}
	}
	.progress {
		height: 5px;
	}
	&:hover {
		.product-img-action-wrap {
			.product-img {
				a {
					img {
						&.hover-img {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
			.product-action-1 {
				opacity: 1;
				visibility: visible;
			}
		}
		.product-img-zoom {
			a {
				img {
					-webkit-transform: scale3d(1.05, 1.05, 1.05) translateZ(0);
					transform: scale3d(1.05, 1.05, 1.05) translateZ(0);
				}
			}
		}
	}
	&.small {
		border: 0;
		text-align: center;
		.rating-result {
			justify-content: center;
		}
		.product-content-wrap {
			padding: 5px 15px 0 15px;
		}
		.product-price {
			padding-top: 0;
		}
		&:hover {
			box-shadow: none;
		}
		h2 {
			border-bottom: 0;
			padding: 0;
			margin-bottom: 0;
		}
		.product-badges span {
			font-size: 10px;
		}
	}
	&.style-2 {
		border: 0;
		padding-bottom: 25px;
		&:hover {
			border: 0;
			box-shadow: none;
		}
		.product-img-action-wrap {
			padding: 0;
			position: relative;
			z-index: 1;
			max-height: unset;
			img {
				border-radius: 15px;
			}
		}
		.product-content-wrap {
			position: relative;
			margin-top: -90px;
			z-index: 3;
			padding: 0;
			max-width: 86%;
			margin-left: auto;
			margin-right: auto;
			@include transistion-duration-3s;
			.deals-content {				
				margin: 0 auto;
				background-color: #fff;
				border-radius: 10px;
				box-shadow: $box-shadown-2;
				padding: 25px 30px;
			}
			.deals-countdown-wrap {
				position: absolute;
				top: -80px;
				width: 100%;
				text-align: center;
			}
		}
		&:hover {
			.product-content-wrap {
				transform: translateY(-5px);
				@include transistion-duration-3s;
			}
		}
	}
}
.product-price {
	font-family: $font-heading;
}
.product-list-small {
	article:not(:last-child) {
		margin-bottom: 20px;
	}
	h6 {		
		a {
			color: $color-heading;
			&:hover {
				color: $color-brand;
			}
		}
	}
	figure {
		img {
			border-radius: 10px;
		}
	}
	.product-price {
		color: $color-brand;
		font-size: 18px;
		font-weight: 700;
		margin-top: 10px;

		span.old-price {
			font-size: $font-sm;
			color: $color-grey-4;
			margin: 0 0 0 7px;
			text-decoration: line-through;
		}
	}
}
.range {
	.list-group-item {
		position: relative;
		display: block;
		padding: 0;
		background: none;
		border: 0;
	}
	.checkbox {
		font-size: 0.8em;
	}
	.price-filter {
		display: block;
		margin-top: 20px;
	}
	#slider-range {
		box-shadow: none;
		border: none;
		height: 4px;
		border-radius: 0px;
		background: $color-brand;
		color: $color-brand;
		.ui-slider-range {
			box-shadow: none;
			background: #222;
			border-radius: 0px;
			border: none;
		}
	}
	.ui-slider-handle {
		&.ui-state-default {
			&.ui-corner-all {
				width: 14px;
				height: 14px;
				line-height: 10px;
				background: $color-brand;
				border: none;
				border-radius: 100%;
				top: -5px;
			}
		}
	}
	.label-input {
		margin-top: 15px;
		span {
			margin-right: 5px;
			color: #282828;
		}
		input {
			border: none;
			margin: 0;
			height: unset;
			font-weight: 600;
			font-size: 14px;
			background: transparent;
			padding-left: 0;
		}
	}
	.check-box-list {
		margin-top: 15px;
		li {
			margin-bottom: 5px;
			&:last-child {
				margin: 0;
			}
			label {
				margin: 0;
				font-size: 14px;
				font-weight: 400;
				color: #333;
				cursor: pointer;
				input {
					display: inline-block;
					margin-right: 6px;
					position: relative;
					top: 1px;
				}
			}
		}
		.count {
			margin-left: 5px;
			color: #666;
		}
	}
}
.btn {
	&.btn-small {
		line-height: 1;
		padding: 10px 15px;
		min-width: unset;
		display: table;
		border-radius: 3px;
	}
}
.product-sidebar {
	.single-post {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 10px;
			border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
			padding-bottom: 10px;
		}
		.content {
			padding-left: 95px;
		}
		i {
			font-size: 12px;
		}
	}
	.image {
		height: 80px;
		width: 80px;
		float: left;
		margin-right: 10px;
		overflow: hidden;
	}
}
.shop-product-fillter {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
			justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
		-ms-flex-align: center;
			align-items: center;
	margin: 0 0 30px;
	&.style-2 {
		padding-bottom: 20px;
		border-bottom: 1px solid $border-color;
	}
	.sort-by-product-area {
		position: relative;
		display: flex;
		.sort-by-cover {
			position: relative;
		}
		.sort-by-product-wrap {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			background: #fff;
			border: 1px solid #f7f8f9;
			color: #777;
			padding: 9px 16px;
			border-radius: 10px;
			-webkit-transition: all .3s ease 0s;
			-o-transition: all .3s ease 0s;
			transition: all .3s ease 0s;
			cursor: pointer;
			border: 1px solid $border-color;
			&:hover {
				box-shadow: $box-shadown-2;
			}
			.sort-by {
				margin-right: 5px;
				i {
					margin-right: 10px;
					font-size: 14px;
					color: #ababab;
					position: relative;
					top: 2px;
				}
				span {
					font-size: 13px;
					font-weight: 500;
				}
			}
			.sort-by-dropdown-wrap {
				span {
					font-size: 13px;
					font-weight: 500;
					color: $color-text;
					i {
						font-size: 15px;
						color: $color-text;
						margin-left: 10px;
						position: relative;
						top: 2px;
					}
				}
			}
		}
		
	}
}
.sort-by-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 99;
	right: 0;
	padding: 16px 0 21px;
	background: #fff;
	border: 0;
	border-radius: 5px;
	visibility: hidden;
	opacity: 0;
	min-width: 100%;
	-webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
	box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
	color: $color-text;
	font-weight: 500;
	&.show {
		opacity: 1;
		visibility: visible;
	}
	ul {
		li {
			display: block;
			a {
				font-weight: 500;
				font-size: 13px;
				padding: 5px 30px;
				display: block;
				position: relative;
				color: $color-text;
				&.active {
					&::before {
						content: "\f143";
						position: absolute;
						top: 5px;
						left: 10px;
						font-size: 12px;
						color: $color-brand;
						font-family: 'uicons-regular-straight'  !important;
						font-weight: 900;
					}
					&:hover {
						&::before {
							color: #fff;
						}
					}
				}
				&:hover {
					background-color: $color-brand;
					color: #ffffff;
				}
			}
		}
	}
}
/*Product list*/
.product-list {
	position: relative;
	.product-cart-wrap {
		border: 0;
		border-radius: 0;
		overflow: unset;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-content: center;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
		&:hover {
			box-shadow: none;
			.product-img {
				-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
			}
		}
		.product-badges.product-badges-position {
			left: 0;
			top: 0;
		}
		.product-img-action-wrap {
			max-width: 28%;
			position: relative;
			padding: 0;
			max-height: unset;
			.product-img {
				border: 1px solid $border-color;
				border-radius: 15px;
				.product-img-inner {
					overflow: hidden;
					padding: 10px;
					a img {
						height: auto;
					}
				}
			}
		}
		
		h2 {
			font-size: 32px;
		}
		.product-content-wrap {
			.product-category a {
				font-size: $font-md;
				margin-top: 15px;
				display: block;
			}
			.product-price span {				
				font-size: 32px;
				&.old-price {
					font-size: 20px;
				}
			}
			.product-action-1 {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				position: relative;
				bottom: unset;
				padding: 0 20px;
				margin-top: 20px;
				box-shadow: $box-shadown-2;
				a.action-btn {
					border: none;
					width: auto;
					border-radius: 50px;
					background-color: $color-brand;
					color: #fff;
					text-align: center;
					margin-right: 0;
					position: relative;
					display: inline-block;
					padding: 0px 23px;
					height: 44px;
					font-weight: 500;
					i {
						margin-right: 8px;
						color: #fff;
					}
				}
			}
		}
		
	}
}

/*PRODUCT DETAILS*/

.detail-gallery {
	position: relative;
	.zoom-icon {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 2;
		font-size: 22px;
		opacity: 0.6;
	}
}
.slider-nav-thumbnails {	
	.slick-list {
		margin: 0 -10px;
	}
	.slick-slide {		
		position: relative;
		border-radius: 15px;
		overflow: hidden;
		img {
			border-radius: 17px;
		}
		&.slick-current {
			&::before {
				border-bottom: 5px solid #333;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				content: "";
				height: 0;
				left: 50%;
				margin-left: -5px;
				position: absolute;
				top: -6px;
				width: 0;
			}
			img {
				border: 2px solid #a2d2c9;
			}
		}
	}
	div {
		&.slick-slide {
			margin: 0 10px;
		}
	}
	button {
		opacity: 0;
		&.slick-arrow {
			margin: 0;
			border: 0;
			background: $background-1;
			border-radius: 40px;
			width: 40px;
			height: 40px;
			line-height: 44px;
			font-size: 24px;
			z-index: 9;
			color: $color-text;
			&:hover {
				color: #fff;
				background-color: $color-brand;
			}
			&.slick-prev {
				left: -20px;
			}
			&.slick-next {
				right: -20px;
			}
		}
		
	}
	&:hover {
		button {
			opacity: 1;
		}
	}
	
}
.slider-nav-thumbnails .slick-prev,
.slider-nav-thumbnails .slick-next {
	font-size: 12px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.product-rate {
	background-image: url('../../../../../public/images/theme/rating-stars.png');
	background-position: 0 -12px;
	background-repeat: repeat-x;
	height: 12px;
	width: 60px;
	transition: all 0.5s ease-out 0s;
	-webkit-transition: all 0.5s ease-out 0s;
}
.product-rating {
	height: 12px;
	background-repeat: repeat-x;
	background-image: url('../../../../../public/images/theme/rating-stars.png');
	background-position: 0 0;
}
.list-filter {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			color: #555;
			display: block;
			min-width: 30px;
			text-align: center;
			position: relative;
			transition: all 0.5s ease-out 0s;
			-webkit-transition: all 0.5s ease-out 0s;
		}
	}
}

.color-filter {
	&.list-filter {
		a {
			span {
				display: block;
				width: 26px;
				height: 26px;
				border-radius: 40px;
				&.product-color-white {
					border: 1px solid #ddd;
				}
			}
		}
		li {
			&.active {
				a {
					&::before {
						content: "";
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						position: absolute;
						right: 3px;
						top: -3px;
						background: #4cd964;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						border: 1px solid #fff;
					}
				}
			}
		}
	}
}
.size-filter {
	&.list-filter {
		a {
			border-radius: 5px;
			padding: 0 9px;
			background: #fff;
		}
		li {
			a {
				color: $color-text;
				height: 32px;
				line-height: 32px;
				min-width: 40px;
				text-align: center;
			}
		}
	}
}
.size-filter.list-filter li a:hover,
.size-filter.list-filter li.active a {
	color: #fff;
	background-color: $color-brand;
	border-color: $color-brand;
}




.detail-qty {
	max-width: 80px;
	padding: 9px 20px;
	position: relative;
	width: 100%;
	border-radius: 5px;
	& > a {
		font-size: 16px;
		position: absolute;
		right: 8px;
		color: $color-brand;
		&:hover {
			color: $color-brand-dark;
		}
		&.qty-up {
			top: 0;
		}
		&.qty-down {
			bottom: -4px;
		}
	}
}
.attr-detail {
	.select-box {
		select {
			height: 40px;
			width: 100%;
		}
	}
	&.attr-brand {
		margin-top: 23px;
		.select-box {
			display: block;
			margin-bottom: 20px;
		}
	}
	&.attr-color {
		table {
			margin-bottom: 15px;
		}
	}
}
.detail-extralink {
	& > div {
		display: inline-block;
		vertical-align: top;
	}
	.detail-qty {
		margin: 0 6px 15px 0;
		background: #fff;
		border: 2px solid $color-brand !important;
		font-size: 16px;
		font-weight: 700;
		color: $color-brand;
		border-radius: 5px;
		padding: 11px 20px 11px 30px;
		max-width: 90px;
	}
}
.stock-status {
	display: inline-block;
	padding: 8px 12px;
	border-radius: 5px;
	margin-bottom: 20px;
	font-family: $font-heading;
	font-size: $font-sm;
	font-weight: 700;
	line-height: 1;
	&.in-stock {
		background: $background-3;
		color: $color-brand;
	}
	&.out-stock {
		color: $color-hot;
		background: #fde0e9;
	}

}
.detail-info {
	.product-price-cover {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.product-price {
			line-height: 1;
		}
	}
	
	.product-price {
		display: flex;
		align-items: center;
		margin: 15px 0 30px 0;
		.current-price {
			font-size: 58px;
			text-decoration: none;
			font-weight: 900;
		}
		.old-price {
			text-decoration: line-through;
			color: $color-muted;
			margin-left: 20px;
			font-size: 28px;
			font-weight: 700;
			display: block;
		}
		.save-price {
			font-size: 12px;
			font-weight: 600;
			margin-bottom: 6px;
			display: block;
			color: $color-brand-2;
		}
	}
	.product-meta {
		border-top: 1px solid $border-color;
		padding-top: 15px;
	}
	
}
.button {
	&.button-add-to-cart {
		padding: 8px 40px;
	}
}
.product-extra-link2 {
	a {
		background: #fff none repeat scroll 0 0;
		border: 1px solid #f1f1f1;
		color: #333;
		border-radius: 5px;
		display: inline-block;
		height: 50px;
		line-height: 55px;
		position: relative;
		text-align: center;
		vertical-align: top;
		width: 50px;
		margin: 0 5px;
		transition: all 0.5s ease-out 0s;
		-webkit-transition: all 0.5s ease-out 0s;
		&:hover {
			background-color: $color-brand;
			color: #fff;
			i {
				opacity: 1;
			}
		}
		i {
			font-size: 18px;
			opacity: 0.6;
		}
	}
	.button.button-add-to-cart {
		position: relative;
		padding: 0px 20px;
		border-radius: 5px;
		border: 0;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: $font-md;
		font-family: $font-heading;
		i {
			margin-right: 10px;
		}
	}
}

.product-info {
	border: 1px solid $border-color;
	border-radius: 15px;
	padding: 40px 50px;
}

.tab-style3 {
	.nav-tabs {
		.nav-item {
			a {
				&.active, &:hover {					
					color: $color-brand;
					box-shadow: $box-shadown-2;
				}
			}
		}
		li {
			&.nav-item {
				a {
					display: block;
					padding: 13px 24px !important;
					text-align: center;
					font-weight: 700;
					font-family: $font-heading;
					text-transform: none;
					font-size: $font-lg;
					border-radius: 30px;
					border: 1px solid $border-color;					
					background: #fff;
					color: $color-text;
				}
			}
		}
	}
}
.tab-content {
	&.shop_info_tab {
		margin-top: 40px;
	}
}
.tab-pane {
	.comments-area {
		padding-top: 0;
		border: 0;
	}
}
.product-color {
	&-red {background:#ff596d;}
	&-yellow{background:#ffdb33;}
	&-white{background:#ffffff;}
	&-orange{background:#ffbb51;}
	&-cyan{background:#80e6ff;}
	&-green{background:#38cf46;}
	&-purple{background:#ff8ff8;}
}
.detail-gallery .product-image-slider {
	background-color: #fff;
	margin-bottom: 30px;
	border-radius: 15px;
	border: 1px solid $border-color;
	overflow: hidden;
	img {
		opacity: 1;
		border-radius: 16px;
	}
	&.slider-nav-thumbnails {
		background: none;
		border-radius: 0;
	}
	button.slick-arrow {
		background: none;
		border: 0;
		padding: 0;
		font-size: 14px;
		i {
			color: $color-grey-4;
		}
	}
}
.mail-to-friend {
	color: $color-grey-4;
	font-size: 12px;
	i {
		margin-right: 5px;
	}
}
.attr-color, .attr-size {
	display: flex;
	align-items: center;
}

.product-detail-rating {
	display: flex;	
	align-items: center;
	padding: 15px 0;
	justify-content: space-between;
}
.product-detail {
	.section-title.style-1 {
		font-size: 22px;
	}
}
.product-more-infor {
	padding: 0 0 0px 14px;
	li {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin: 0 0 10px;
		position: relative;
		::before {
			position: absolute;
			left: -14px;
			top: 9px;
			content: "";
			height: 6px;
			width: 6px;
			border-radius: 100%;
			background-color: #9b9b9b;
		}
		span {
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 165px;
			-ms-flex: 0 0 165px;
			flex: 0 0 165px;
			display: inline-block;
		}
		&:last-child {
			margin: 0 0 0;
		}
	}
}
.progress {
	& + .progress {
		margin-top: 1rem;
	}
	span {
		line-height: 16px;
		background: #fff;
		padding-right: 10px;
		width: 45px;
	}
}
.progress-bar {
	background-color: $color-brand;
	
}
.shop-filter-toogle {
	margin-bottom: 20px;
	display: inline-flex;
	align-items: center;
	position: relative;
	border-radius: 30px;
	border: 1px solid $border-color;
	font-size: $font-lg;
	font-weight: 700;
	font-family: $font-heading;
	box-shadow: $box-shadown-2;
	padding: 10px 20px;
	i {
		margin-left: 5px;
		color: $color-muted;
		&.angle-down {
			opacity: 0;
			visibility: hidden;
			display: none;
		}
	}
	.fi-rs-filter {
		font-size: 14px;
		color: $color-muted;
	}
	&.active {
		i {
			&.angle-down {
				opacity: 1;
				visibility: visible;
				display: inline-block;
				
			}
			&.angle-up {
				opacity: 0;
				visibility: hidden;
				display: none;
			}
		}
	}
}
.shop-product-fillter-header {
	.card {
		border: 1px solid $border-color;
		border-radius: 20px;
		margin-bottom: 50px;
		padding: 30px 40px;
		-webkit-box-shadow: $box-shadown-3;
		box-shadow: $box-shadown-3;
	}
	
	.categor-list {
		li {
			font-size: 12px;
			& + li {
				border-top: 1px solid #f7f8f9;
				padding-top: 5px;
				margin-top: 5px;
			}
			a {
				font-size: 14px;
				color: $color-text;
				margin-right: 10px;
				&:hover {
					color: $color-brand;
				}
			}
		}
	}
	.color-filter {
		border-bottom: 1px solid $border-color;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}
	.product-rate-cover {
		& + .product-rate-cover {
			margin-top: 15px;
		}
	}
}
.shopping-summery {
	table {
		> :not(caption) > * > * {
			padding: 15px 0;
		}
		td, th, thead  {	
			border: 0;
		}
		thead {			
			th {
				background-color: $border-color;
				padding: 18px 0;
				font-family: $font-heading;
				font-size: $font-lg;
				font-weight: 700;
				color: $color-heading;
				&.start {
					border-radius: 20px 0 0 20px;
				}
				&.end {
					border-radius: 0 20px 20px 0;
				}
			}
		}		
		tbody tr img {
			max-width: 120px;
			border: 1px solid $border-color;
			border-radius: 15px;
		}
	}
	
}
table.no-border {
	td, th, thead  {	
		border: 0;
	}
}
.shipping_calculator {
	.custom_select {
		.select2-container {
			max-width: unset;
		}
		.select2-container--default .select2-selection--single {
			border-radius: 10px;
			height: 64px;
			line-height: 64px;
			.select2-selection__rendered {
				line-height: 64px;
			}
			.select2-selection__arrow {
				top: 50%;
				right: 14px;
				transform: translateY(-50%);
			}
		}
	}
	.w-100 {
		.select2-container {
			max-width: unset;
			min-width: 445.5px;
		}
	}
}
.cart-totals {
	border-radius: 15px;
	box-shadow: $box-shadown-2;
	padding: 30px 40px;
}
.order_review {
	border: 1px solid $border-color;
	padding: 30px;
	border-radius: 10px;
}
.toggle_info {
	padding: 12px 20px;
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid $border-color;
	box-shadow: $box-shadown-2;
}
.login_form .panel-body, .coupon_form .panel-body {
	border: 1px solid $border-color;
	padding: 30px;
	margin-top: 30px;
	border-radius: 10px;
}
.payment_option .custome-radio{margin-bottom:10px;}
.payment_option .custome-radio .form-check-label{color:#292b2c;font-weight:600;}
.custome-radio .form-check-label::before{content:"";border:1px solid #908f8f;height:16px;width:16px;display:inline-block;border-radius:100%;vertical-align:middle;margin-right:8px;}
.custome-radio input[type="radio"] + .form-check-label::after{content:"";height:10px;width:10px;border-radius:100%;position:absolute;top:9px;left:3px;opacity:0;}
.custome-radio input[type="radio"]:checked + .form-check-label::after{opacity:1;background-color: $color-brand;}

.related-products {
	.product-img-action-wrap {padding: 0;margin-bottom: 15px;}
}
.col-lg-4-5 {
	.product-cart-wrap .product-action-1 a.action-btn {
		width: 35px;
	}
	.countdown-section {
		.countdown-amount {
			width: 38px;
		}
	}
}
.zoomWindow {
	border-radius: 15px;
	overflow: hidden;
}

.table-wishlist {
	border: 0;
}
.apply-coupon {
	display: flex;
	input {
		height: 51px;
		border-radius: 10px 0 0 10px;
		background-image: url('../../../../../public/images/theme/icons/coupon.png');
		background-position: 20px center;
		background-repeat: no-repeat;
		padding-left: 50px;
	}
	button {
		min-width: 150px;
		height: 51px;
		border-radius: 0 10px 10px 0;
		background-color: $color-heading;
		&:hover {
			background-color: $color-brand;
		}
	}
}
.order_table table {
	.product-thumbnail img {
		max-width: 120px;
		border-radius: 15px;
		border: 1px solid $border-color;
		padding: 5px;
	}
	.w-160 {
		max-width: 160px;
	}
}
.table.table-compare {
	border-radius: 15px;
	> :not(caption) > * > * {
		padding: 30px 0;
	}
}
.mw-200 {
	min-width: 200px;
}