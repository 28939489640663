/*COMPONENTS -> SLIDER*/
.single-animation-wrap {
	&.slick-active {
		.slider-animated-1 {			
			h1 {
				-webkit-animation-delay: 1.4s;
				animation-delay: 1.4s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			h2 {
				-webkit-animation-delay: 1.2s;
				animation-delay: 1.2s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			h3 {
				-webkit-animation-delay: 1.0s;
				animation-delay: 1.0s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			h4 {
				-webkit-animation-delay: 1.0s;
				animation-delay: 1.0s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			span {
				-webkit-animation-delay: .5s;
				animation-delay: .5s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			p {
				-webkit-animation-delay: 1.7s;
				animation-delay: 1.7s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			a.btn {
				-webkit-animation-delay: 2.0s;
				animation-delay: 2.0s;
				-webkit-animation-name: fadeInUp;
				animation-name: fadeInUp;
			}
			&.slider-product-price {
				-webkit-animation-delay: 1.2s;
				animation-delay: 1.2s;
				-webkit-animation-name: flipInY;
				animation-name: flipInY;
			}
			.single-slider-img {
				img {
					-webkit-animation-delay: 1.5s;
					animation-delay: 1.5s;
					-webkit-animation-name: fadeInUp;
					animation-name: fadeInUp;
				}
			}
		}
	}
}
.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap,
.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap-2 {
	-webkit-animation-delay: 1.0s;
	animation-delay: 1.0s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.hero-slider-1 {
	position: relative;
	.single-hero-slider {
		height: 538px;
		border-radius: 30px;
		background-size: cover;
		background-position: center center;
		&.rectangle {
			border-radius: 0;
			.slider-content {
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%) translateX(-50%);
				text-align: center;
				form {
					margin: 0 auto;
				}
			}
		}
	}
	img {
		max-height: 538px;
		border-radius: 30px;
	}
	.slider-content {
		position: absolute;
		top: 50%;
		left: 6%;
		transform: translateY(-50%);
		p {
			font-size: 30px;
		}
		form {
			background-color: #fff;
			max-width: 450px;
			border-radius: 50px;
			input {
				border: 0;
				border-radius: 50px 0 0 50px;
				padding-left: 58px;
				background: url('../../../../../public/images/theme/icons/icon-plane.png') no-repeat 25px center;
			}
			button {
				border: 0;
				border-radius: 50px;				
			}
		}
	}

	.single-slider-img-1 {
		height: 538px;
		position: relative;
		.slider-1-1 {
			position: absolute;
			bottom: 30px;
			right: 0;
		}
		.slider-1-2 {
			position: absolute;
			bottom: 20px;
			right: 0;
		}
		.slider-1-3 {
			position: absolute;
			bottom: 30px;
			right: 0;
		}
	}

	&.style-5 {
		.display-2 {
			font-size: 50px;
		}
		.slider-content p {
			font-size: 24px;
		}
		img {
			border-radius: 10px;
		}
		.single-hero-slider {
			border-radius: 10px;
		}
	}
}

.dot-style-1 {
	ul {
		display: flex;
		li {
			margin: 0 3px;
			button {
				width: 15px;
				height: 15px;
				border-radius: 30px;
				border: 1px solid;
				padding: 0;
				font-size: 0px;
				border-color: $color-heading;
				background: none;
				-webkit-transition: all 0.3s linear;
				-o-transition: all 0.3s linear;
				transition: all 0.3s linear;
				&:hover {
					background: $border-color-2;
				}
			}
			&.slick-active {
				button {
					background: $color-brand;
					border-color: $color-brand;
				}
			}
		}
	}
	&.dot-style-1-position-1 {
		ul {
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			bottom: 15px;
		}
	}
	&.dot-style-1-position-2 {
		ul {
			position: absolute;
			left: 6%;
			bottom: 15px;
		}
	}
	&.dot-style-1-center {
		ul {
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}
	&.dot-style-1-mt1 {
		ul {
			margin-top: 30px;
		}
	}
}

.slider-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	width: 100%;
	.slider-btn {
	cursor: pointer;
	background: $background-1;
	width: 45px;
	height: 45px;
	position: absolute;
	display: block;
	z-index: 100;
	border-radius: 50%;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	transition: all .2s ease-out;
	text-align: center;
	line-height: 45px;
	font-size: 16px;
	color: $color-text;
	&.slider-prev {
		left: 20px;
		i {
			margin-right: 2px;
		}
	}
	&.slider-next {
		right: 20px;
		i {
			margin-left: 2px;
		}
	}
	&:hover {
		background-color: $color-brand;
		color: #fff;
		border-color: $color-brand;
	}
	
	
	}
	&.slider-arrow-2 {
		.slider-btn {
			width: 40px;
			height: 40px;
			line-height: 44px;
			font-size: 24px;
		}	
		&.flex-right {
			display: flex;
			position: relative;
			max-width: 200px;
			justify-content: flex-end;
			top: unset;
			transform: unset;
			.slider-btn {
				position: relative;
				left: unset;
				right: unset;
				&.slider-prev {
					margin-right: 10px;
				}
			}
		}	
	}
	&.slider-arrow-3 {
		.slider-btn {
			width: 30px;
			height: 30px;
			line-height: 28px;
			font-size: 12px;
			margin-top: -15px;
		}
	}
	&.style-3 {
		.slider-btn {
			width: 40px;
			height: 40px;
			border: 1px solid #dcdeed;
			line-height: 40px;
			font-size: 12px;
			margin-top: -20px;
		}
	}
	
}
.home-slide-cover {
	position: relative;
	.slider-arrow {
		left: 0;
	}
	.hero-slider-content-2 {
		padding-left: 50px;
	}
}
/*Carausel*/
.carausel-8-columns-cover {	
	.carausel-8-columns {
		overflow: hidden;
		margin: 0 -12px;
		.card-1 {
			margin-right: 12px;
			margin-left: 12px;
		}
	}
	.product-img {
		border: 1px solid #cce7d0;
	}
	.slider-arrow {
		top: -80px;
		.slider-btn.slider-next {
			right: 0;
		}
		.slider-btn.slider-prev {
			right: 48px;
			left: unset;
		}
	}
	&.arrow-center {
		.slider-arrow {
			top: 50%;
			transform: translateY(-50%);
			margin-top: -50px;
			.slider-btn.slider-next {
				right: -30px;
			}
			.slider-btn.slider-prev {
				left: -30px;
			}
			&.slider-arrow-3 {
				margin-top: 0;
			}
		}	
	}
	
}

.carausel-10-columns-cover {	
	.carausel-10-columns {
		overflow: hidden;
		margin: 0 -12px;
		.card-2 {
			margin-right: 12px;
			margin-left: 12px;
		}
	}

}
.carausel-4-columns-cover {
	
	.carausel-4-columns {
		overflow: hidden;
		margin: 0 -12px;
		.product-cart-wrap {
			margin-right: 12px;
			margin-left: 12px;
		}
	}
	.carausel-4-columns-arrow {
		margin-top: -100px;
	}
	.product-cart-wrap {
		margin-bottom: 20px;
		&:hover {
			box-shadow: $box-shadown-2;
		}
	}
}
.bg-grey-10 {
	background-color: #d0f3ec;
}

.home-slider {
	.slider-arrow {
		opacity: 0;
		visibility: hidden;
		transition: 0.4s;
	}
	&:hover {
		.slider-arrow {
			opacity: 1;
			visibility: visible;
			transition: 0.4s;
		}
	}
	&.style-2 {
		// background:url('../../../../../public/images/banner/banner-12.png') no-repeat center bottom;
        background-size: cover;
        width: 100%;
		padding: 50px 0;
	}
}

.carausel-3-columns-cover  {
	position: relative;
	.carausel-3-columns {
		overflow: hidden;
		margin: 0 -12px;
		img {
			margin-right: 12px;
			margin-left: 12px;
		}
	}
	#carausel-3-columns-arrows {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		width: 100%;
		z-index: 3;
	}
	.slider-btn {
		display: inline-block;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		line-height: 44px;
		text-align: center;
		background: $background-1;
		font-size: 27px;
		color: $color-brand;
		position: absolute;
		&.slider-prev {
			left: -20px;			
		}
		&.slider-next {
			right: -20px;
		}
		&:hover {
			background: $color-brand;
			color: #fff;
		}
	}
}

